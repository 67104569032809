/* website: 3166-westowne
 * created at 2021-05-19 10:15 by jyburtschell
 */

// Import all makes and organization styles files
@import "../templates/makes/mazda.scss";
@import "../utils/icons.scss";

.widget-sr{
  .showroom-vdp-used-car-info{
    .car-name{
      font-size: 35px !important;
      line-height: 40px !important;
      white-space: nowrap;
    }
  }
}